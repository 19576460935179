import { useCallback, useEffect } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { Button, Grid } from "@mui/material";
import { useRecoilState } from "recoil";

import { GET_PURCHASE_ORDER_PRODUCTS_RES } from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/adminBidCreate";
import { FORWARDING_ADMIN_BID_CREATE_ATOMS } from "@sellernote/_shared/src/states/forwarding/adminBidCreate";
import { BidCreateFormData } from "@sellernote/_shared/src/types/forwarding/adminBidCreate";

import ItemTotalSummary from "./ItemTotalSummary";
import ProductsInfoForm from "./ProductsInfoForm";

function ProductsInfo({
  productsData,
}: {
  productsData: GET_PURCHASE_ORDER_PRODUCTS_RES | undefined;
}) {
  const [cargoInfoFormType, setCargoInfoFormType] = useRecoilState(
    FORWARDING_ADMIN_BID_CREATE_ATOMS.CARGO_INFO_FORM_TYPE
  );

  const { control, setValue, watch } = useFormContext<BidCreateFormData>();

  const freightType = watch("freightType");

  const { insert, fields } = useFieldArray({
    control,
    name: "productsInfo",
  });

  const productsInfoRef = useCallback(
    (node) => {
      if (node !== null) {
        if (fields.length === 0) {
          insert(0, {
            name: "",
            quantity: undefined,
            isDangerous: false,
            isDouble: true,
            vertical: undefined,
            horizontal: undefined,
            height: undefined,
            volumeUnit: "CM",
            packingType: "",
            cbm: undefined,
            weight: undefined,
            weightUnit: "KG",
            unitSupply: 0,
            needRefrigeration: freightType === "AIR" ? false : undefined,
          });
        }
      }
    },
    [fields, freightType, insert]
  );

  useEffect(() => {
    return () => {
      setValue("productsInfo", undefined);
      setCargoInfoFormType("totalVolume");
    };
  }, [setCargoInfoFormType, setValue]);

  const handleProductsItemAdd = () => {
    if (fields) {
      insert(fields.length + 1, {
        name: "",
        quantity: undefined,
        isDangerous: false,
        isDouble: true,
        vertical: undefined,
        horizontal: undefined,
        height: undefined,
        volumeUnit: "CM",
        packingType: "",
        cbm: undefined,
        weight: undefined,
        weightUnit: "KG",
        unitSupply: 0,
        needRefrigeration: freightType === "AIR" ? false : undefined,
      });
      return;
    }

    return;
  };

  const handleCargoInfoFormTypeChange = () => {
    if (cargoInfoFormType === "totalVolume") {
      setCargoInfoFormType("packaging");
      setValue("productsInfo", [
        {
          ...watch(`productsInfo.${0}`),
        },
      ]);
      return;
    }

    setCargoInfoFormType("totalVolume");
    setValue("productsInfo", [
      {
        ...watch(`productsInfo.${0}`),
        cbm: watch("totalCBM") ?? 0,
      },
    ]);
  };

  return (
    <Grid item container xs={11} ref={productsInfoRef}>
      {fields?.map((productsItem, index) => {
        return (
          <ProductsInfoForm
            key={productsItem.id}
            formIndex={index}
            productsData={productsData}
          />
        );
      })}

      {cargoInfoFormType === "packaging" && (
        <Grid item>
          <Button onClick={handleProductsItemAdd}>추가</Button>
        </Grid>
      )}

      <Grid item>
        <Button variant="outlined" onClick={handleCargoInfoFormTypeChange}>
          {cargoInfoFormType === "totalVolume"
            ? "포장별 입력으로 변경"
            : "총 부피/중량으로 변경"}
        </Button>
      </Grid>

      <ItemTotalSummary control={control} setValue={setValue} />
    </Grid>
  );
}

export default ProductsInfo;
