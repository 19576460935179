import { useMemo } from "react";

import ADMIN_BID_CREATE_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_BID_CREATE_QUERY";
import { FreightType } from "@sellernote/_shared/src/types/common/common";
import {
  BidCreateFormData,
  BidCreateQuotationStorageData,
} from "@sellernote/_shared/src/types/forwarding/adminBidCreate";

function useGetSearchEstimatePayload({
  sessionStorageQuotationInfo,
  bidCreateFormData,
  freightType,
}: {
  sessionStorageQuotationInfo: BidCreateQuotationStorageData | null;
  bidCreateFormData: BidCreateFormData | undefined;
  freightType: FreightType;
}) {
  const { data: fclEstimate, isFetching: isFclEstimateFetching } =
    ADMIN_BID_CREATE_QUERY.useGetFclEstimate({
      params: sessionStorageQuotationInfo?.fclSearchEstimatePayload,
      enabled: bidCreateFormData?.bidType === "import" && freightType === "FCL",
    });

  const { data: airEstimate, isFetching: isAirEstimateFetching } =
    ADMIN_BID_CREATE_QUERY.useGetAirEstimate({
      params: sessionStorageQuotationInfo?.airSearchEstimatePayload,
      enabled: bidCreateFormData?.bidType === "import" && freightType === "AIR",
    });

  const { data: lclEstimate, isFetching: isLclEstimateFetching } =
    ADMIN_BID_CREATE_QUERY.useGetLclEstimate({
      params: sessionStorageQuotationInfo?.lclSearchEstimatePayload,
      enabled: bidCreateFormData?.bidType === "import" && freightType === "LCL",
    });

  const isEstimateFetching = useMemo(() => {
    if (bidCreateFormData?.bidType === "export") {
      return false;
    }
    if (freightType === "FCL") {
      return isFclEstimateFetching;
    }

    if (freightType === "AIR") {
      return isAirEstimateFetching;
    }

    return isLclEstimateFetching;
  }, [
    bidCreateFormData?.bidType,
    freightType,
    isAirEstimateFetching,
    isFclEstimateFetching,
    isLclEstimateFetching,
  ]);

  const estimate = useMemo(() => {
    if (bidCreateFormData?.bidType === "export") {
      return;
    }

    if (freightType === "FCL") {
      return fclEstimate;
    }

    if (freightType === "AIR") {
      return airEstimate;
    }

    return lclEstimate;
  }, [
    bidCreateFormData?.bidType,
    freightType,
    lclEstimate,
    fclEstimate,
    airEstimate,
  ]);

  return {
    isEstimateFetching,
    estimate,
  };
}

export default useGetSearchEstimatePayload;
