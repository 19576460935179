import { useAtomValue } from "jotai";

import ADMIN_ACCOUNT_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_ACCOUNT_QUERY";
import { FORWARDING_ACCOUNT_ATOMS } from "@sellernote/_shared-for-forwarding-admin/src/jotaiStates/account";

const useGetAccountDetail = () => {
  const selectedId = useAtomValue(FORWARDING_ACCOUNT_ATOMS.ACCOUNT_DETAIL_ID);

  const { data: detailData } = ADMIN_ACCOUNT_QUERY.useGetAccountDetail({
    id: selectedId ?? 0,
  });

  return {
    detailData,
  };
};

export default useGetAccountDetail;
