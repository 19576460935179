import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { Grid, Typography } from "@mui/material";

import ADMIN_BID_CREATE_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_BID_CREATE_QUERY";
import { BidCreateStorageData } from "@sellernote/_shared/src/types/forwarding/adminBidCreate";

import ContainersInfo from "./ContainersInfo";
import ProductsInfo from "./ProductsInfo";

function CargoInfo({
  POId,
  sessionStorageShipmentCreateInfo,
}: {
  POId?: number;
  sessionStorageShipmentCreateInfo: BidCreateStorageData;
}) {
  const { watch } = useFormContext();

  const { data: productsData } =
    ADMIN_BID_CREATE_QUERY.useGetPurchaseOrderProducts({
      onlyUnmappedBid: true,
      POId,
      enabled: !!POId && !!sessionStorageShipmentCreateInfo.isOpenApiAuth,
    });

  const freightType = watch("freightType");

  const ItemInfoByFreightType = useMemo(() => {
    if (freightType === "FCL") {
      return <ContainersInfo productsData={productsData} />;
    }
    if (freightType === "LCL" || freightType === "AIR") {
      return <ProductsInfo productsData={productsData} />;
    }
  }, [freightType, productsData]);

  return (
    <Grid item container alignItems="flex-start">
      <Grid item xs={1}>
        <Typography variant="subtitle1" component="div">
          물품 정보
        </Typography>
      </Grid>

      {ItemInfoByFreightType}
    </Grid>
  );
}

export default CargoInfo;
