import React from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";

//TODO : 엔트리 컴포넌트의 이름을 page경로 그대로 수정
import NewWindowOpenFilePreview from "@sellernote/_shared-for-forwarding-admin/src/containers/FileName/FilePreview/NewWindowOpenFilePreview";

import AccountManagement from "pages/account-management";
import AdminUser from "pages/admin-user";
import AdminUserDetail from "pages/admin-user/:id";
import ChangeAdminUserPassword from "pages/admin-user/change-password";
import Bid from "pages/bid";
import ChangeQuotationDetailByFreightType from "pages/bid/change-quotation/:id/:freightType";
import ChangeUserQuotation from "pages/bid/change-user-quotation/:id";
import ConfirmQuotationDetail from "pages/bid/confirm-quotation/:id";
import BidCreate from "pages/bid/create";
import BidCreateQuotation from "pages/bid/create/quotation/:freightType";
import BusinessOverview from "pages/business-management/business-overview";
import BusinessManagementForOperation from "pages/business-management/operation";
import Profit from "pages/business-management/profit";
import DepositManagement from "pages/lab/deposit-management";
import OrderDetail from "pages/order/:id";
import OrderSub from "pages/order/sub";
import PartnerManagement from "pages/partner-management";
import PartnerDetail from "pages/partner-management/:id";
import Promotion from "pages/promotion";
import PromotionDetail from "pages/promotion/:id";
import SettlementDepositManagement from "pages/settlement/deposit-management";
import PurchaseManagement from "pages/settlement/purchaseManagement";
import SettlementSalesManagement from "pages/settlement/salesManagement";
import SettlementWithdrawalManagement from "pages/settlement/withdrawal-management";
import VesselScheduleAir from "pages/vessel-schedule/air";
import VesselScheduleFcl from "pages/vessel-schedule/fcl";
import VesselScheduleLclPort from "pages/vessel-schedule/lcl/port";
import VesselScheduleLclWarehouse from "pages/vessel-schedule/lcl/warehouse";
import AdminUserRegister from "./pages/admin-user/register";
import BidApply from "./pages/bid/applyBid/index";
import BidDetail from "./pages/bid/detail/:id";
import CouponDetail from "./pages/coupon/:id";
import Coupon from "./pages/coupon/index";
import Dashboard from "./pages/dashboard";
import FareManagementAirList from "./pages/fare-management/air";
import FareManageFclList from "./pages/fare-management/fcl";
import FareManagementLclList from "./pages/fare-management/lcl";
import InlandFareAirList from "./pages/inland-fare/air/list";
import InlandFareFclList from "./pages/inland-fare/fcl/list";
import InlandFareLclList from "./pages/inland-fare/lcl/list";
import Login from "./pages/login";
import Order from "./pages/order";
import CountryList from "./pages/place/country";
import PortList from "./pages/place/ports";
import ZoneList from "./pages/place/zones";
import EmailBrochures from "./pages/promotion/email-brochures";
import GuideUser from "./pages/promotion/guide";
import Settlement from "./pages/settlement";
import TeamDetail from "./pages/team/:id";
import TeamList from "./pages/team/list";
import TradingStatement from "./pages/tradingStatement/:invoiceType/:bidId/shipmentType";
import ConsolidationTrelloList from "./pages/trello/consolidation";
import ExportTrelloList from "./pages/trello/export";
import GeneralTrelloList from "./pages/trello/general";
import UserManagement from "./pages/users/list";
import UserDetail from "./pages/users/list/:id";

//TODO: url 명 kebab-case로 변경

function AppRouter({ children }: { children: React.ReactNode }) {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Redirect to="/dashboard" />
        </Route>

        <Route exact path="/login" component={Login} />

        <Route exact path="/dashboard" component={Dashboard} />

        <Route exact path="/admin-user" component={AdminUser} />
        <Route
          exact
          path="/admin-user/register"
          component={AdminUserRegister}
        />
        <Route exact path="/admin-user/:id" component={AdminUserDetail} />
        <Route
          exact
          path="/admin-user/change-password/:id"
          component={ChangeAdminUserPassword}
        />

        <Route
          exact
          path="/vessel-schedule/fcl"
          component={VesselScheduleFcl}
        />
        <Route
          exact
          path="/vessel-schedule/lcl/port"
          component={VesselScheduleLclPort}
        />
        <Route
          exact
          path="/vessel-schedule/lcl/warehouse"
          component={VesselScheduleLclWarehouse}
        />
        <Route
          exact
          path="/vessel-schedule/air"
          component={VesselScheduleAir}
        />

        <Route
          exact
          path="/bid/confirm-quotation/:id"
          component={ConfirmQuotationDetail}
        />

        <Route
          exact
          path="/bid/change-quotation/:id/:frightType"
          component={ChangeQuotationDetailByFreightType}
        />

        <Route
          exact
          path="/bid/change-user-quotation/:id/"
          component={ChangeUserQuotation}
        />

        <Route exact path="/bid/create" component={BidCreate} />
        <Route
          exact
          path="/bid/create/quotation/:freightType"
          component={BidCreateQuotation}
        />

        <Route exact path="/bid" component={Bid} />
        <Route exact path="/bid/detail/:id" component={BidDetail} />
        <Route
          exact
          path="/bid/applyBid/:freightType/:id"
          component={BidApply}
        />

        <Route
          exact
          path="/tradingStatement/:invoiceType/:bidId/:shipmentType"
          component={TradingStatement}
        />

        <Route
          exact
          path="/inland-fare/fcl/list"
          component={InlandFareFclList}
        />

        <Route
          exact
          path="/inland-fare/lcl/list"
          component={InlandFareLclList}
        />

        <Route
          exact
          path="/inland-fare/air/list"
          component={InlandFareAirList}
        />

        <Route exact path="/place/zones" component={ZoneList} />
        <Route exact path="/place/ports" component={PortList} />

        <Route exact path="/place/countryList" component={CountryList} />

        <Route exact path="/team/list" component={TeamList} />
        <Route exact path="/team/:id" component={TeamDetail} />

        <Route exact path="/partner-management" component={PartnerManagement} />
        <Route exact path="/partner-management/:id" component={PartnerDetail} />

        <Route exact path="/users/list" component={UserManagement} />
        <Route exact path="/users/list/:id" component={UserDetail} />

        <Route
          exact
          path="/fare-management/fcl/list"
          component={FareManageFclList}
        />

        <Route
          exact
          path="/fare-management/lcl/list"
          component={FareManagementLclList}
        />

        <Route
          exact
          path="/fare-management/air/list"
          component={FareManagementAirList}
        />

        <Route exact path="/settlement" component={Settlement} />
        <Route
          exact
          path="/settlement/salesManagement"
          component={SettlementSalesManagement}
        />
        <Route
          exact
          path="/settlement/purchaseManagement"
          component={PurchaseManagement}
        />
        <Route
          exact
          path="/settlement/deposit-management"
          component={SettlementDepositManagement}
        />
        <Route
          exact
          path="/settlement/withdrawal-management"
          component={SettlementWithdrawalManagement}
        />

        <Route exact path="/trello/general" component={GeneralTrelloList} />
        <Route
          exact
          path="/trello/consolidation"
          component={ConsolidationTrelloList}
        />
        <Route exact path="/trello/export" component={ExportTrelloList} />

        <Route exact path="/coupon" component={Coupon} />
        <Route exact path="/coupon/:id" component={CouponDetail} />

        <Route
          exact
          path="/business-management/operation"
          component={BusinessManagementForOperation}
        />
        <Route
          exact
          path="/business-management/business-overview"
          component={BusinessOverview}
        />
        <Route exact path="/business-management/profit" component={Profit} />
        <Route exact path="/business-management/guide" component={GuideUser} />

        <Route
          exact
          path="/file/preview"
          component={NewWindowOpenFilePreview}
        />

        <Route exact path="/promotion" component={Promotion} />
        <Route
          exact
          path="/promotion/email-brochures"
          component={EmailBrochures}
        />

        <Route exact path="/promotion/:id" component={PromotionDetail} />

        <Route exact path="/order" component={Order} />
        <Route exact path="/order/sub" component={OrderSub} />
        <Route exact path="/order/:id" component={OrderDetail} />
        <Route exact path="/order/sub/:id" component={OrderDetail} />

        <Route
          exact
          path="/lab/deposit-management"
          component={DepositManagement}
        />

        {/** 계정 > 거래처 관리 */}
        <Route exact path="/account-management" component={AccountManagement} />
      </Switch>

      {children}
    </Router>
  );
}
export default AppRouter;
