import { useMemo } from "react";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button, Grid } from "@mui/material";

import { GET_PURCHASE_ORDER_PRODUCTS_RES } from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/adminBidCreate";
import { BidCreateFormData } from "@sellernote/_shared/src/types/forwarding/adminBidCreate";
import AutoCompleteWithReactHookForm from "@sellernote/_shared-for-forwarding-admin/src/components/AutoCompleteWithReactHookForm";
import TextFieldWithReactHookForm from "@sellernote/_shared-for-forwarding-admin/src/components/TextFieldWithReactHookForm";

import { checkCanUpdateSeparator } from "../../utils";

export default function ProductNameList({
  formIndex,
  productsData,
}: {
  formIndex: number;
  productsData: GET_PURCHASE_ORDER_PRODUCTS_RES | undefined;
}) {
  const { control, setValue } = useFormContext<BidCreateFormData>();

  const { remove, append, fields } = useFieldArray({
    control,
    name: `containersInfo.${formIndex}.products`,
  });

  const watchedContainersInfo = useWatch({
    control,
    name: "containersInfo",
  });

  /**
   * 사용한 발주 품명을 다른 인풋에서는 제거하기 위한 옵션리스트
   */
  const productsNameOptionList = useMemo(() => {
    const productsArray = watchedContainersInfo
      ?.map(({ products, ...containerItem }) => {
        return products;
      })
      ?.flat()
      .map((v) => {
        return v?.productName;
      });

    if (productsArray) {
      return productsData?.filter((v) => {
        return !productsArray.includes(v.productName);
      });
    }

    return productsData;
  }, [productsData, watchedContainersInfo]);

  return (
    <Grid container spacing={1}>
      {fields.map((field, index) => {
        return (
          <Grid container item spacing={2} key={field.id}>
            <Grid item flex={2} alignItems={"center"}>
              <AutoCompleteWithReactHookForm
                fullWidth
                name={`containersInfo.${formIndex}.products.${index}.productName`}
                control={control}
                options={(productsNameOptionList || []).map((v) => {
                  return v.productName;
                })}
                handleEffectOnChange={(data) => {
                  const separator = productsData?.find((v) => {
                    return v.productName === data;
                  })?.separator;

                  if (separator) {
                    setValue(
                      `containersInfo.${formIndex}.products.${index}.separator`,
                      separator
                    );
                  }
                  return;
                }}
              />
            </Grid>

            <Grid item flex={1}>
              <TextFieldWithReactHookForm
                fullWidth
                name={`containersInfo.${formIndex}.products.${index}.separator`}
                control={control}
                handleEffectOnChange={(e) => {
                  // API 요청 시 빈스트링인 경우 에러를 냄
                  if (!e?.target.value) {
                    setValue(
                      `containersInfo.${formIndex}.products.${index}.separator`,
                      undefined
                    );
                  }
                }}
                disabled={
                  !checkCanUpdateSeparator({
                    productsData,
                    currentProductName: field.productName,
                  })
                }
              />
            </Grid>

            <Grid item>
              <Button
                disabled={fields.length === 1}
                color="warning"
                size="small"
                onClick={() => remove(index)}
              >
                <DeleteIcon />
              </Button>
            </Grid>
          </Grid>
        );
      })}

      <Grid item>
        <Button
          variant="contained"
          color="warning"
          onClick={() => {
            append({
              productName: "",
              separator: undefined,
            });
          }}
          size="small"
          endIcon={<AddCircleIcon />}
        >
          품명 추가
        </Button>
      </Grid>
    </Grid>
  );
}
