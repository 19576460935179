import { useCallback } from "react";
import { useFormContext } from "react-hook-form";

import { GET_ADMIN_ZONE_ADDRESS_RES } from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/adminZoneAddress";
import { ZONE_ID_OF_FULFILLMENT_CENTER_INCHEON_1 } from "@sellernote/_shared/src/constants/forwarding/common";
import { BidCreateFormData } from "@sellernote/_shared/src/types/forwarding/adminBidCreate";

export default function useSyncZoneId({
  address,
  prevAddress,
  setPrevAddress,
  zoneId,
  zoneAddress,
  setIsBofulAddress,
}: {
  address: string | undefined;
  prevAddress: string | undefined;
  setPrevAddress: (value: string | undefined) => void;
  zoneId: number | undefined;
  zoneAddress: GET_ADMIN_ZONE_ADDRESS_RES | undefined;
  setIsBofulAddress: (value: boolean) => void;
}) {
  const { setValue } = useFormContext<BidCreateFormData>();

  const syncZoneId = useCallback(() => {
    if (address !== prevAddress || !zoneId) {
      setPrevAddress(address);
      if (zoneAddress && zoneAddress.length > 0) {
        setValue(
          "zoneId",
          zoneAddress?.find((v) => {
            return v.roadAddress === address || v.jibunAddress === address;
          })?.id
        );
      }

      /** 견적확정에서 돌아왔을 때 보풀 주소가 있으면 보풀체크를 true로 변경 */
      if (!prevAddress && zoneId === ZONE_ID_OF_FULFILLMENT_CENTER_INCHEON_1) {
        setIsBofulAddress(true);
      }
    }
  }, [
    address,
    prevAddress,
    setIsBofulAddress,
    setPrevAddress,
    setValue,
    zoneAddress,
    zoneId,
  ]);

  return {
    /**
     * address를 입력 시 zoneId를 등록해주는 로직
     * - 국내 주소라 수입인경우 endAddress, 수출인경우 startAddress를 사용
     * - address과 기존 주소(prevAddress)와 다르거나 zoneId가 없을때(첫 주소 등록 시)
     */
    syncZoneId,
  };
}
