import { useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { Button, CircularProgress, Grid } from "@mui/material";
import { useRecoilValue } from "recoil";

import {
  CHECK_IS_OCEANTICKET_RES,
  CREATE_BID_REQ_AIR,
  CREATE_BID_REQ_DESTINATION,
  CREATE_BID_REQ_FCL,
  CREATE_BID_REQ_LCL,
  CREATE_BID_REQ_ORIGIN,
} from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/adminBidCreate";
import ADMIN_BID_CREATE_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_BID_CREATE_QUERY";
import { FORWARDING_ADMIN_BID_CREATE_ATOMS } from "@sellernote/_shared/src/states/forwarding/adminBidCreate";
import { FreightType, Port } from "@sellernote/_shared/src/types/common/common";
import {
  BidCreateFormData,
  BidCreateStorageData,
  BidCreateUserData,
} from "@sellernote/_shared/src/types/forwarding/adminBidCreate";
import {
  getCountryOfPort,
  getPortId,
  handleShipmentCreateSuccess,
} from "@sellernote/_shared/src/utils/forwarding/admin/adminBidCreate";
import useSnackbar from "@sellernote/_shared-for-forwarding-admin/src/hooks/useSnackbar";

import { getContainersInfoPayload } from "../utils";

import useGetProductsInfoWithUnitSupply from "../../useGetProductsInfoWithUnitSupply";

export default function useBidCreateButton({
  portList,
  formData,
  isOceanticket,
  freightType,
  checkBlankInBidCreateForm,
  hasPO,
  POId,
  formIndex,
  handleSessionStorageShipmentCreateInfoChange,
  sessionStorageShipmentCreateInfo,
  sessionStorageShipmentCreateUserInfo,
}: {
  portList: Port[];
  formData: BidCreateFormData;
  isOceanticket: CHECK_IS_OCEANTICKET_RES | undefined;
  freightType: FreightType;
  checkBlankInBidCreateForm: (formData: BidCreateFormData) => boolean;
  hasPO?: boolean;
  POId?: number;
  formIndex: number;
  handleSessionStorageShipmentCreateInfoChange: (
    value: BidCreateStorageData
  ) => void;
  sessionStorageShipmentCreateInfo: BidCreateStorageData;
  sessionStorageShipmentCreateUserInfo: BidCreateUserData;
}) {
  const { handleSnackbarOpen } = useSnackbar();

  const history = useHistory();

  const cargoInfoFormType = useRecoilValue(
    FORWARDING_ADMIN_BID_CREATE_ATOMS.CARGO_INFO_FORM_TYPE
  );

  const { mutate: createBid, isLoading: createdBidLoading } =
    ADMIN_BID_CREATE_QUERY.useCreateBid();

  const getRequestOriginData = useCallback(() => {
    const origin: CREATE_BID_REQ_ORIGIN = {
      startCountry:
        formData.startType !== "inland"
          ? getCountryOfPort(portList, formData.startPort)
          : getCountryOfPort(portList, formData.startViaPort),
      startPortId:
        formData.startType !== "inland"
          ? getPortId(portList, formData.startPort)
          : undefined,
      startViaPortId:
        formData.startType === "inland"
          ? getPortId(portList, formData.startViaPort)
          : undefined,
      startAddress: formData.startAddress ?? undefined,
      startType: formData.startType ?? undefined,
    };

    return origin;
  }, [
    formData.startAddress,
    formData.startPort,
    formData.startType,
    formData.startViaPort,
    portList,
  ]);

  const getRequestDestinationData = useCallback(() => {
    const origin: CREATE_BID_REQ_DESTINATION = {
      endCountry:
        formData.endType !== "inland"
          ? getCountryOfPort(portList, formData.endPort)
          : getCountryOfPort(portList, formData.endViaPort),
      endPortId:
        formData.endType !== "inland"
          ? getPortId(portList, formData.endPort)
          : undefined,
      endViaPortId:
        formData.endType === "inland"
          ? getPortId(portList, formData.endViaPort)
          : undefined,
      endAddress: formData.endAddress ?? undefined,
      endType: formData.endType,
      endAddressDetail: formData.endAddressDetail ?? undefined,
      zoneId: formData.endType === "inland" ? formData.zoneId : undefined,
    };

    return origin;
  }, [
    formData.endAddress,
    formData.endAddressDetail,
    formData.endPort,
    formData.endType,
    formData.endViaPort,
    formData.zoneId,
    portList,
  ]);

  /**
   * 발주물품 정보를 물품정보 폼에서 추출해주는 함수
   */
  const getPOProducts = useCallback(() => {
    if (
      freightType === "FCL" &&
      sessionStorageShipmentCreateInfo.isOpenApiAuth
    ) {
      const productsArray = formData.containersInfo?.map(
        ({ products, ...containerItem }) => {
          return products;
        }
      );

      return productsArray?.flat() as
        | {
            productName: string;
            separator?: string;
          }[]
        | undefined;
    }

    if (sessionStorageShipmentCreateInfo.isOpenApiAuth) {
      return formData.products;
    }
  }, [
    formData.containersInfo,
    formData.products,
    freightType,
    sessionStorageShipmentCreateInfo.isOpenApiAuth,
  ]);

  const getRequestFclData = useCallback(() => {
    const fcl: CREATE_BID_REQ_FCL = {
      containerAccessable:
        formData.endType === "inland"
          ? formData.containerAccessable === "TRUE"
          : undefined,
      containOceanSurcharge: formData.containOceanSurcharge
        ? formData.containOceanSurcharge === "TRUE"
        : undefined,
      containLss: formData.containLss
        ? formData.containLss === "TRUE"
        : undefined,
      containersInfo: getContainersInfoPayload({
        containersInfo: formData.containersInfo,
        isOpenApiAuth: sessionStorageShipmentCreateInfo.isOpenApiAuth,
      }),
    };

    return fcl;
  }, [
    formData.containLss,
    formData.containOceanSurcharge,
    formData.containerAccessable,
    formData.containersInfo,
    formData.endType,
    sessionStorageShipmentCreateInfo.isOpenApiAuth,
  ]);

  const { getProductsInfoWithUnitSupply } = useGetProductsInfoWithUnitSupply({
    freightType,
    cargoInfoFormType,
    createFormData: formData,
  });

  const getRequestLclData = useCallback(() => {
    const lcl: CREATE_BID_REQ_LCL = {
      supply: formData.supply,
      containOceanSurcharge: formData.containOceanSurcharge
        ? formData.containOceanSurcharge === "TRUE"
        : undefined,
      containLss: formData.containLss
        ? formData.containLss === "TRUE"
        : undefined,
      totalCBM:
        cargoInfoFormType === "totalVolume" ? undefined : formData.totalCBM,
      totalWeight:
        cargoInfoFormType === "totalVolume" ? undefined : formData.totalWeight,
      productsInfo: getProductsInfoWithUnitSupply(),
    };

    return lcl;
  }, [
    cargoInfoFormType,
    formData.containLss,
    formData.containOceanSurcharge,
    formData.supply,
    formData.totalCBM,
    formData.totalWeight,
    getProductsInfoWithUnitSupply,
  ]);

  const getRequestAirData = useCallback(() => {
    const air: CREATE_BID_REQ_AIR = {
      supply: formData.supply,
      productsInfo: getProductsInfoWithUnitSupply(),
    };

    return air;
  }, [formData.supply, getProductsInfoWithUnitSupply]);

  const handleBidCreate = useCallback(() => {
    // 도착지 셀렉트에서 입력만 하고 선택하지 않았을 때
    if (!formData.zoneId && formData.endAddress) {
      handleSnackbarOpen(
        "도착지 주소가 선택되지 않았습니다. 확인해주세요",
        "warning"
      );
      return;
    }

    createBid(
      {
        pathParams: {
          userId: sessionStorageShipmentCreateUserInfo.userId,
          teamId: sessionStorageShipmentCreateUserInfo.teamId,
        },
        origin: getRequestOriginData(),
        destination: getRequestDestinationData(),
        fcl: formData.freightType === "FCL" ? getRequestFclData() : undefined,
        lcl: formData.freightType === "LCL" ? getRequestLclData() : undefined,
        air: formData.freightType === "AIR" ? getRequestAirData() : undefined,
        freightType: formData.freightType,
        serviceType: isOceanticket ? "oceanTicket" : "general",
        transportType: formData.freightType === "AIR" ? "air" : "sea",
        /** 현재는 1로 고정 */
        exportersCount: 1,

        incoterms: formData.incoterms,
        ...(formData.endType === "inland" && {
          inlandType: (() => {
            if (formData.freightType === "FCL") {
              // FCL이면 독차 고정
              return "sole";
            }

            return formData.inlandType;
          })(),
        }),
        containCustoms: formData.containCustoms,
        containDomesticFee: formData.containDomesticFee
          ? formData.containDomesticFee === "TRUE"
          : false,
        hopeCargoInsurance: formData.hopeCargoInsurance,
        freeStorageDays:
          isOceanticket && formData.containsWarehouseFee === "TRUE"
            ? 7
            : undefined,
        needFTACertificateAgency: formData.needFTACertificateAgency,
        needIORAgency: formData.needIORAgency,
        isFixed: false,
        hasPo: hasPO,
        poId: POId,
        products: getPOProducts(),
        invoicePrice: sessionStorageShipmentCreateInfo.isOpenApiAuth
          ? formData.invoicePrice
          : undefined,
      },
      {
        onSuccess: (response) => {
          const handleMoveToOrderPage = () => {
            history.push(`/order/${sessionStorageShipmentCreateInfo.POId}`);
          };

          const handleMoveToShipmentDetailPage = () => {
            history.replace(`/bid/detail/${response.data.id}`);
          };

          handleShipmentCreateSuccess({
            hasPO,
            sessionStorageShipmentCreateInfo: sessionStorageShipmentCreateInfo,
            formIndex,
            formData,
            handleMoveToOrderPage,
            handleMoveToShipmentDetailPage,
            handleSessionStorageShipmentCreateInfoChange,
          });
        },

        onError: () => {
          handleSnackbarOpen("의뢰 생성에 실패했습니다.", "error");
        },
      }
    );
  }, [
    formData,
    createBid,
    sessionStorageShipmentCreateUserInfo.userId,
    sessionStorageShipmentCreateUserInfo.teamId,
    getRequestOriginData,
    getRequestDestinationData,
    getRequestFclData,
    getRequestLclData,
    getRequestAirData,
    isOceanticket,
    hasPO,
    POId,
    getPOProducts,
    sessionStorageShipmentCreateInfo,
    handleSnackbarOpen,
    formIndex,
    handleSessionStorageShipmentCreateInfoChange,
    history,
  ]);

  const CreateBidButton = useMemo(() => {
    return (
      <Grid item>
        <Button
          disabled={checkBlankInBidCreateForm(formData) || createdBidLoading}
          type="submit"
          variant="contained"
        >
          {createdBidLoading ? (
            <CircularProgress color="secondary" size={24} />
          ) : (
            "의뢰 생성"
          )}
        </Button>
      </Grid>
    );
  }, [checkBlankInBidCreateForm, createdBidLoading, formData]);

  return { CreateBidButton, handleBidCreate };
}
