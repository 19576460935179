import { useCallback, useRef, useState } from "react";

import { CREATE_ACCOUNT_REQ } from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/adminAccount";
import { AccountDetailInfo } from "@sellernote/_shared/src/types/forwarding/adminAccount";

import {
  AccountCategoryState,
  UpdateCategoryProps,
  UpdateFormProps,
} from "../../types";
import getCategoryState from "./utils";

const useHandleCreateForm = ({
  detailData,
}: {
  detailData: AccountDetailInfo | undefined;
}) => {
  const [newAccountForm, setNewAccountForm] = useState<CREATE_ACCOUNT_REQ>({});

  const prevDetailDataRef = useRef<AccountDetailInfo | undefined>();

  /** 상세가 아닌 경우 매입, 매출 전부 false. 상세인 경우 데이터를 참조함 */
  const [category, setCategory] = useState<AccountCategoryState>(() =>
    getCategoryState(detailData)
  );

  /** detailData가 업데이트 되면, category state를 갱신함 */
  if (detailData !== prevDetailDataRef.current) {
    prevDetailDataRef.current = detailData;
    setCategory(getCategoryState(detailData));
  }

  const handleFormUpdate = useCallback(
    ({ key, value }: UpdateFormProps) => {
      setNewAccountForm({
        ...newAccountForm,
        [key]: value,
      });
    },
    [newAccountForm]
  );

  const handleCategoryUpdate = useCallback(
    ({ key, value }: UpdateCategoryProps) => {
      setCategory({
        ...category,
        [key]: value,
      });
    },
    [category]
  );

  const resetForm = useCallback(() => {
    setNewAccountForm({});

    setCategory({
      sales: false,
      payable: false,
    });
  }, []);

  return {
    newAccountForm,
    handleFormUpdate,
    category,
    handleCategoryUpdate,
    resetForm,
  };
};

export default useHandleCreateForm;
