import { UseFormSetValue } from "react-hook-form";

import { CREATE_BID_REQ_FCL_CONTAINERS_INFO } from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/adminBidCreate";
import {
  DETAIL_ADDRESS_OF_FULFILLMENT_CENTER_INCHEON_1,
  ROAD_ADDRESS_OF_FULFILLMENT_CENTER_INCHEON_1,
} from "@sellernote/_shared/src/constants/common/common";
import { ZONE_ID_OF_FULFILLMENT_CENTER_INCHEON_1 } from "@sellernote/_shared/src/constants/forwarding/common";
import { ArrayElement } from "@sellernote/_shared/src/types/common/customUtilityTypes";
import {
  BidCreateFormData,
  ShipmentCreateContainersInfo,
} from "@sellernote/_shared/src/types/forwarding/adminBidCreate";

function getContainersInfoPayload({
  containersInfo,
  isOpenApiAuth,
}: {
  containersInfo: ShipmentCreateContainersInfo[] | undefined;
  isOpenApiAuth: boolean | undefined;
}): CREATE_BID_REQ_FCL_CONTAINERS_INFO {
  return (containersInfo || [])?.reduce(
    (
      a: CREATE_BID_REQ_FCL_CONTAINERS_INFO,
      containerInfo,
      containerInfoIndex
    ) => {
      const joinedName =
        containerInfo.products
          ?.map((product) => {
            return product.productName;
          })
          .join(",") ?? "";

      const subList = containerInfo.containerList.map((v) => {
        const item: ArrayElement<CREATE_BID_REQ_FCL_CONTAINERS_INFO> = {
          // OPEN API 유저가 아니라면 일반 name을 사용
          name: isOpenApiAuth ? joinedName : containerInfo.name,
          isDangerous: containerInfo.isDangerous,
          itemGroup: containerInfoIndex + 1,
          quantity: v.quantity,
          containerType: v.containerType,
        };

        return item;
      });

      return [...a, ...subList];
    },
    []
  );
}

/**
 * 수입/수출 의뢰 생성시 풀필먼트 주소를 셋팅해주는 함수
 *   - 수입일때는 도착지 주소를 수출일때는 출발지 주소로 셋팅해준다.
 */
const handleBofulAddressCheck = ({
  isBofulAddress,
  setIsBofulAddress,
  setValue,
  isImport,
}: {
  isBofulAddress: boolean;
  setIsBofulAddress: (value: boolean) => void;
  setValue: UseFormSetValue<BidCreateFormData>;
  isImport: boolean;
}) => {
  const addressKey = isImport ? "startAddress" : "endAddress";
  const addressDetailKey = isImport ? "startAddressDetail" : "endAddressDetail";

  if (isBofulAddress) {
    setIsBofulAddress(false);
    setValue(addressKey, undefined);
    setValue(addressDetailKey, undefined);
    setValue("zoneId", undefined);
    return;
  }
  setIsBofulAddress(true);
  setValue(addressKey, ROAD_ADDRESS_OF_FULFILLMENT_CENTER_INCHEON_1);
  setValue(addressDetailKey, DETAIL_ADDRESS_OF_FULFILLMENT_CENTER_INCHEON_1);
  setValue("zoneId", ZONE_ID_OF_FULFILLMENT_CENTER_INCHEON_1);
  return;
};

export { getContainersInfoPayload, handleBofulAddressCheck };
