import { Control, Controller } from "react-hook-form";
import { Typography } from "@mui/material";

import { REQUEST_PARTNER_EMAIL_REQ } from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/adminBid";
import TextField from "@sellernote/_shared-for-admin/src/components/TextField";

export default function CommentForm({
  control,
}: {
  control: Control<REQUEST_PARTNER_EMAIL_REQ>;
}) {
  return (
    <>
      <Typography variant="h6" component="div">
        코멘트
      </Typography>

      <Controller
        control={control}
        name={"comment"}
        render={({ field: { ref, ...field } }) => {
          return (
            <TextField
              {...field}
              variant={"standard"}
              InputLabelProps={{ shrink: true }}
              fullWidth
              size="small"
              inputRef={ref}
            />
          );
        }}
      />
    </>
  );
}
