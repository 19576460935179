import { useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";

import { GET_ADMIN_BID_PARTNER_QUOTE_RES } from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/adminBid";
import { AdminBidDetail } from "@sellernote/_shared/src/types/forwarding/adminBid";

import RequestQuoteToPartnerModal from "../../../_components/RequestQuoteToPartnerModal";

export default function RequestQuoteToPartnerModalContainer({
  copiedBidId,
  showsRequestQuoteToPartnerModal,
  setShowsRequestQuoteToPartnerModal,
  setShowsBidCopyModal,
  partnerQuote,
  bidDetail,
}: {
  copiedBidId: number;
  showsRequestQuoteToPartnerModal: boolean;
  setShowsRequestQuoteToPartnerModal: (v: boolean) => void;
  setShowsBidCopyModal: (v: boolean) => void;
  partnerQuote: GET_ADMIN_BID_PARTNER_QUOTE_RES | undefined;
  bidDetail: AdminBidDetail;
}) {
  const history = useHistory();

  // receiverList는 서버에서 확인해줘서 요청 시 필요없지만 language로 영문품목 확인을 위해 데이터 생성
  const receiverList = useMemo(() => {
    return partnerQuote?.list.map((v) => {
      return {
        language: v.language,
        mainManager: "",
        needsPlainTextEmail: v.needsPlainTextEmail,
        name: v.name,
        emailList: [""],
        partnerCompany: v.id,
      };
    });
  }, [partnerQuote?.list]);

  const handleBidCopyModalClose = useCallback(() => {
    history.push(`/bid/detail/${copiedBidId}`);
    setShowsRequestQuoteToPartnerModal(false);
    setShowsBidCopyModal(false);
  }, [
    copiedBidId,
    history,
    setShowsBidCopyModal,
    setShowsRequestQuoteToPartnerModal,
  ]);

  return showsRequestQuoteToPartnerModal && receiverList && bidDetail ? (
    <RequestQuoteToPartnerModal
      isBidCopy={true}
      receiverList={receiverList}
      bidDetail={bidDetail}
      showsRequestQuoteModal={showsRequestQuoteToPartnerModal}
      setShowsRequestQuoteModal={setShowsRequestQuoteToPartnerModal}
      handleBidCopyModalClose={handleBidCopyModalClose}
      copiedBidId={copiedBidId}
    />
  ) : null;
}
