import { useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { Button, CircularProgress, Grid } from "@mui/material";
import { useRecoilValue } from "recoil";

import {
  CREATE_BID_REQ_AIR,
  CREATE_BID_REQ_DESTINATION,
  CREATE_BID_REQ_FCL,
  CREATE_BID_REQ_LCL,
  CREATE_BID_REQ_ORIGIN,
} from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/adminBidCreate";
import ADMIN_BID_CREATE_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_BID_CREATE_QUERY";
import { FORWARDING_ADMIN_BID_CREATE_ATOMS } from "@sellernote/_shared/src/states/forwarding/adminBidCreate";
import { FreightType, Port } from "@sellernote/_shared/src/types/common/common";
import {
  BidCreateFormData,
  BidCreateStorageData,
  BidCreateUserData,
} from "@sellernote/_shared/src/types/forwarding/adminBidCreate";
import {
  getCountryOfPort,
  getPortId,
} from "@sellernote/_shared/src/utils/forwarding/admin/adminBidCreate";
import useSnackbar from "@sellernote/_shared-for-forwarding-admin/src/hooks/useSnackbar";

import { getContainersInfoPayload } from "../utils";

import useGetProductsInfoWithUnitSupply from "../../useGetProductsInfoWithUnitSupply";

export default function useCreateExportButton({
  portList,
  formData,
  freightType,
  checkBlankInBidCreateForm,
  sessionStorageShipmentCreateInfo,
  sessionStorageShipmentCreateUserInfo,
}: {
  portList: Port[];
  formData: BidCreateFormData;
  freightType: FreightType;
  checkBlankInBidCreateForm: (formData: BidCreateFormData) => boolean;

  sessionStorageShipmentCreateInfo: BidCreateStorageData;
  sessionStorageShipmentCreateUserInfo: BidCreateUserData;
}) {
  const { handleSnackbarOpen } = useSnackbar();

  const history = useHistory();

  const cargoInfoFormType = useRecoilValue(
    FORWARDING_ADMIN_BID_CREATE_ATOMS.CARGO_INFO_FORM_TYPE
  );

  const { mutate: createExportBid, isLoading: createdExportBidLoading } =
    ADMIN_BID_CREATE_QUERY.useCreateExportBid();

  const getRequestOriginData = useCallback(() => {
    const origin: CREATE_BID_REQ_ORIGIN = {
      startCountry:
        formData.startType !== "inland"
          ? getCountryOfPort(portList, formData.startPort)
          : getCountryOfPort(portList, formData.startViaPort),
      startPortId:
        formData.startType !== "inland"
          ? getPortId(portList, formData.startPort)
          : undefined,
      startViaPortId:
        formData.startType === "inland"
          ? getPortId(portList, formData.startViaPort)
          : undefined,
      startAddress: formData.startAddress ?? undefined,
      startAddressDetail: formData.startAddressDetail ?? undefined,
      startType: formData.startType,
      zoneId: formData.startType === "inland" ? formData.zoneId : undefined,
    };

    return origin;
  }, [
    formData.startAddress,
    formData.startAddressDetail,
    formData.startPort,
    formData.startType,
    formData.startViaPort,
    formData.zoneId,
    portList,
  ]);

  const getRequestDestinationData = useCallback(() => {
    const origin: CREATE_BID_REQ_DESTINATION = {
      endCountry:
        formData.endType !== "inland"
          ? getCountryOfPort(portList, formData.endPort)
          : getCountryOfPort(portList, formData.endViaPort),
      endPortId:
        formData.endType !== "inland"
          ? getPortId(portList, formData.endPort)
          : undefined,
      endViaPortId:
        formData.endType === "inland"
          ? getPortId(portList, formData.endViaPort)
          : undefined,
      endAddress: formData.endAddress ?? undefined,
      endType: formData.endType ?? undefined,
    };

    return origin;
  }, [
    formData.endAddress,
    formData.endPort,
    formData.endType,
    formData.endViaPort,
    portList,
  ]);

  const { getProductsInfoWithUnitSupply } = useGetProductsInfoWithUnitSupply({
    freightType,
    cargoInfoFormType,
    createFormData: formData,
  });

  const getRequestFclData = useCallback(() => {
    const fcl: CREATE_BID_REQ_FCL = {
      containerAccessable:
        formData.endType === "inland"
          ? formData.containerAccessable === "TRUE"
          : undefined,
      containOceanSurcharge: formData.containOceanSurcharge
        ? formData.containOceanSurcharge === "TRUE"
        : undefined,
      containLss: formData.containLss
        ? formData.containLss === "TRUE"
        : undefined,
      containersInfo: getContainersInfoPayload({
        containersInfo: formData.containersInfo,
        isOpenApiAuth: sessionStorageShipmentCreateInfo.isOpenApiAuth,
      }),
    };

    return fcl;
  }, [
    formData.containLss,
    formData.containOceanSurcharge,
    formData.containerAccessable,
    formData.containersInfo,
    formData.endType,
    sessionStorageShipmentCreateInfo.isOpenApiAuth,
  ]);

  const getRequestLclData = useCallback(() => {
    const lcl: CREATE_BID_REQ_LCL = {
      supply: formData.supply,

      totalCBM:
        cargoInfoFormType === "totalVolume" ? undefined : formData.totalCBM,
      totalWeight:
        cargoInfoFormType === "totalVolume" ? undefined : formData.totalWeight,
      productsInfo: getProductsInfoWithUnitSupply(),
    };

    return lcl;
  }, [
    cargoInfoFormType,
    formData.supply,
    formData.totalCBM,
    formData.totalWeight,
    getProductsInfoWithUnitSupply,
  ]);

  const getRequestAirData = useCallback(() => {
    const air: CREATE_BID_REQ_AIR = {
      supply: formData.supply,
      productsInfo: getProductsInfoWithUnitSupply(),
    };

    return air;
  }, [formData.supply, getProductsInfoWithUnitSupply]);

  const handleExportBidCreate = useCallback(() => {
    // 도착지 셀렉트에서 입력만 하고 선택하지 않았을 때
    if (!formData.zoneId && formData.startAddress) {
      handleSnackbarOpen(
        "출발지 주소가 선택되지 않았습니다. 확인해주세요",
        "warning"
      );
      return;
    }

    createExportBid(
      {
        pathParams: {
          userId: sessionStorageShipmentCreateUserInfo.userId,
          teamId: sessionStorageShipmentCreateUserInfo.teamId,
        },
        origin: getRequestOriginData(),
        destination: getRequestDestinationData(),
        fcl: formData.freightType === "FCL" ? getRequestFclData() : undefined,
        lcl: formData.freightType === "LCL" ? getRequestLclData() : undefined,
        air: formData.freightType === "AIR" ? getRequestAirData() : undefined,
        freightType: formData.freightType,
        transportType: formData.freightType === "AIR" ? "air" : "sea",
        /** 현재는 1로 고정 */
        importerCount: 1,
        // 요청 시 값이 없으면 false로 보내야함
        additional: {
          importCustoms: formData.importCustoms ?? false,
          useVGM: formData.useVGM ?? false,
          exportCustoms: formData.exportCustoms ?? false,
          hopeCargoInsurance: formData.hopeCargoInsurance ?? false,
        },
        checkpoint: {
          // FCL일때만 사용 그 외는 해당 없음 해당없음은 false보낸다.
          containerStuffing:
            freightType === "FCL"
              ? formData.containerStuffing === "TRUE"
              : false,
          inlandTransportType: (() => {
            if (formData.startType !== "inland") {
              return "none";
            }

            if (formData.freightType === "FCL") {
              // FCL이면 독차 고정
              return "sole";
            }

            return formData.inlandTransportType || "none";
          })(),
        },
        incoterms: formData.incoterms,
        isFixed: false,
      },
      {
        onSuccess: (response) => {
          /** 수출에서는 발주 > 의뢰 생성이 없기 때문에 생성 후 바로 의뢰상세로 이동 */
          history.replace(`/bid/detail/${response.data.id}`);
        },

        onError: () => {},
      }
    );
  }, [
    formData.zoneId,
    formData.startAddress,
    formData.freightType,
    formData.importCustoms,
    formData.useVGM,
    formData.exportCustoms,
    formData.hopeCargoInsurance,

    formData.containerStuffing,
    formData.startType,
    formData.incoterms,
    formData.inlandTransportType,
    createExportBid,
    sessionStorageShipmentCreateUserInfo.userId,
    sessionStorageShipmentCreateUserInfo.teamId,
    getRequestOriginData,
    getRequestDestinationData,
    getRequestFclData,
    getRequestLclData,
    getRequestAirData,
    freightType,
    handleSnackbarOpen,
    history,
  ]);

  const CreateExportBidButton = useMemo(() => {
    return (
      <Grid item>
        <Button
          disabled={
            checkBlankInBidCreateForm(formData) || createdExportBidLoading
          }
          type="submit"
          variant="contained"
        >
          {createdExportBidLoading ? (
            <CircularProgress color="secondary" size={24} />
          ) : (
            "수출 의뢰 생성"
          )}
        </Button>
      </Grid>
    );
  }, [checkBlankInBidCreateForm, createdExportBidLoading, formData]);

  return { CreateExportBidButton, handleExportBidCreate };
}
